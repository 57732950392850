import React, { useState } from "react";
import "./cta.css";
import { useTranslation } from "react-i18next";

const CTA = () => {
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    contactMethod: "email",
    message: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log("FORM DATA EMAIL:")
      console.log(formData.email)
      const response = await fetch("http://localhost:5000/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to: "samuelnocita@gmail.it",
          from: "samnocita@gwtech.it",
          subject: `New Appointment Request from ${formData.name} ${formData.surname}`,
          text: `${formData.name} ${formData.surname} has requested to contact GreenWaves Technologies.\nThey cared to let us know the following:\n ${formData.message}\nThey also let us know they would prefer to be contacted by  ${formData.contactMethod}`,
          html: `${formData.name} ${formData.surname} has requested to contact GreenWaves Technologies.\nThey cared to let us know the following:\n ${formData.message}\nThey also let us know they would prefer to be contacted by  ${formData.contactMethod}`,
        }),
      });

      if (response.ok) {
        alert("Your request has been submitted and an email has been sent!");
      } else {
        const errorData = await response.json();
        console.error("Backend Error Details:", errorData);
        alert(`Failed to send email: ${errorData.error?.[0]?.message || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Frontend Connection Error:", error);
      alert("A connection error occurred. Please check your backend.");
    }
  };


  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const { t } = useTranslation();

  return (
    <div>
      <div className="greentech__cta">
        <div className="greentech__cta-content">
          <p id="contacts">{t("cta.contact")}</p>
          <h3>{t("cta.writeToday")}</h3>
        </div>
        <div className="greentech__cta-btn">
          <button onClick={openModal} className="scheduler-trigger" type="button">
            {t("cta.getStarted")}
          </button>
        </div>
      </div>
      <div>
        {isModalOpen && (
          <div
            id="scheduler"
            style={{
              maxWidth: "600px",
              margin: "0 auto",
              padding: "20px",
              fontFamily: "'Manrope', sans-serif",
              color: "white",
            }}
          >
            <button id="close-scheduler" className="close-button" onClick={closeModal}>
              X
            </button>
            <h2 style={{ marginBottom: "2.5rem" }}>Schedule an Appointment</h2>
            <form onSubmit={handleSubmit}>
              <div style={{ marginBottom: "15px" }}>
                <label htmlFor="name" style={{ display: "block", marginBottom: "5px" }}>
                  Name:
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  style={{ width: "100%", padding: "8px", border: "1px solid #ccc", borderRadius: "4px" }}
                />
              </div>

              <div style={{ marginBottom: "15px" }}>
                <label htmlFor="surname" style={{ display: "block", marginBottom: "5px" }}>
                  Surname:
                </label>
                <input
                  type="text"
                  id="surname"
                  name="surname"
                  value={formData.surname}
                  onChange={handleChange}
                  required
                  style={{ width: "100%", padding: "8px", border: "1px solid #ccc", borderRadius: "4px" }}
                />
              </div>

              <div style={{ marginBottom: "15px" }}>
                <label htmlFor="email" style={{ display: "block", marginBottom: "5px" }}>
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  style={{ width: "100%", padding: "8px", border: "1px solid #ccc", borderRadius: "4px" }}
                />
              </div>

              <div style={{ marginBottom: "15px" }}>
                <label style={{ display: "block", marginBottom: "5px" }}>Preferred Contact Method:</label>
                <div>
                  <label style={{ marginRight: "15px" }}>
                    <input
                      type="radio"
                      name="contactMethod"
                      value="email"
                      checked={formData.contactMethod === "email"}
                      onChange={handleChange}
                    />{" "}
                    Email
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="contactMethod"
                      value="phone"
                      checked={formData.contactMethod === "phone"}
                      onChange={handleChange}
                    />{" "}
                    Phone
                  </label>
                </div>
              </div>

              <div style={{ marginBottom: "15px" }}>
                <label htmlFor="message" style={{ display: "block", marginBottom: "5px" }}>
                  Message (optional):
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  style={{ width: "100%", padding: "8px", border: "1px solid #ccc", borderRadius: "4px" }}
                ></textarea>
              </div>

              <button
                type="submit"
                style={{
                  padding: "10px 15px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Submit
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default CTA;
