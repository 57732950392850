import React from 'react';
import { BsInstagram, BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Gallery.css';

const Gallery = () => {


   const scrollRef = React.useRef(null);

   const scroll = (direction) => {
      const { current } = scrollRef;

      if (direction === 'left') {
         current.scrollLeft -= 300;
      } else {
         current.scrollLeft += 300;
      }
   };

   return (
      <div>
         <div id="findus" className="app__gallery flex__center">
            <div className="app__gallery-content">
               <SubHeading title="Instagram" />
               <h1 id="gallery__title">Find us</h1>
            </div>
            <div className="app__gallery-images" >
               <div></div>
               <div className="app__gallery-images_container" ref={scrollRef}>
                  {[images.gallery01, images.gallery02, images.gallery03, images.gallery04, images.gallery05, images.gallery06, images.gallery07].map((image, index) => (
                     <div className="app__gallery-images_card flex__center" key={`gallery_image-${index + 1}`}>
                        <img src={image} alt="gallery_image" />
                        <BsInstagram className="gallery__image-icon" />
                     </div>
                  ))}
               </div>
               <div className="app__gallery-images_arrows">
                  <BsArrowLeftShort className="gallery__arrow-icon" onClick={() => scroll('left')} />
                  <BsArrowRightShort className="gallery__arrow-icon" onClick={() => scroll('right')} />
               </div>
            </div>
         </div>
         <div className='map'>
            <iframe src="https://storage.googleapis.com/maps-solutions-iwe0dxway6/locator-plus/sds7/locator-plus.html"
               title="Embedded content from Google Maps"
               width={"90%"}
               height={"650xp"}
               style={{ margin: "0 5%", borderRadius: "5px" }}
               loading="lazy">
            </iframe>
         </div>
         <div>

         </div>
      </div >
   );
};

export default Gallery;