import gallery01 from '../assets/office/intern_01.jpg'
import gallery02 from '../assets/office/intern_02.jpg'
import gallery03 from '../assets/office/intern_03.jpg'
import gallery04 from '../assets/office/intern_04.jpg'
import gallery05 from '../assets/office/extern_01.jpg'
import gallery06 from '../assets/office/extern_02.jpg'
import gallery07 from '../assets/office/extern_03.jpg'

const images = {
   gallery01,
   gallery02,
   gallery03,
   gallery04,
   gallery05,
   gallery06,
   gallery07
};

export default images;